export const ROUTE_PATHS = {
  ACCUEIL: '/accueil',
  DISTRIBUTION: '/applications/:idApplication/prompts/distribution',
  ERROR: '/error',
  CONVERSATION: '/conversation/:usage',
  PROMPT: '/applications/:idApplication/prompts/:idPrompt',
  PROMPT_CREATE: '/applications/:idApplication/prompts/create',
  PROMPT_EDIT: '/applications/:idApplication/prompts/:idPrompt/edit',
  PROMPTS: '/applications/:idApplication/prompts',
  TAG_ADMIN: '/tags',
}
