import { useParams } from 'react-router-dom'
import { usageParams } from 'constants/usage'
import { generatePath, useNavigate } from 'react-router'
import { useEffect } from 'react'
import { ROUTE_PATHS } from 'constants/routePath'
import { ConversationContent } from 'components/conversation/ConversationContent'

export const Conversation = () => {
  const { usage } = useParams()
  const current = usageParams.find((param) => param.usageKey === usage)
  const navigate = useNavigate()
  useEffect(() => {
    if (!current) {
      navigate(generatePath(ROUTE_PATHS.ACCUEIL))
    }
  }, [])

  return current ? <ConversationContent current={current} /> : null
}
