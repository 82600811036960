import { useForm } from 'react-hook-form'
import { ITag } from 'interfaces/Tag'
import { useCreateTagMutation, useUpdateTagMutation } from 'api/tag'
import { toast } from 'components/common/Toast'
import { useTranslation } from 'react-i18next'

export type TagForm = {
  intitule: string
}
export const useEditTag = (
  setModalOpen?: (value: boolean) => void,
  tag?: ITag | null
) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isValid },
  } = useForm<TagForm>({
    defaultValues: {
      intitule: tag?.nomTag || '',
    },
  })

  const [createTag, { isLoading: isLoadingCreate }] = useCreateTagMutation()
  const [updateTag, { isLoading: isLoadingUpdate }] = useUpdateTagMutation()

  const onSubmit = handleSubmit((data) => {
    if (tag && setModalOpen) {
      updateTag({
        idTag: tag.id,
        nomTag: data.intitule,
      })
        .unwrap()
        .then((tag) => {
          if (tag.id) {
            toast.success(t('tags.form.edit.success'))
            setModalOpen(false)
          }
        })
    } else {
      createTag({
        nomTag: data.intitule,
      })
        .unwrap()
        .then((tag) => {
          if (tag.id) {
            setValue('intitule', '')
            toast.success(t('tags.form.create.success'))
          }
        })
    }
  })

  return {
    register,
    onSubmit,
    setError,
    isValid,
    errors,
    isLoading: isLoadingCreate || isLoadingUpdate,
    setValue,
  }
}
