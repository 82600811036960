import React from 'react'
import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'
import { PromptDistributionRow } from 'components/prompts/PromptDistributionRow'
import { ReactComponent as EyeOpened } from 'assets/images/openedEye.svg'
import { ReactComponent as EyeClosed } from 'assets/images/closedEye.svg'
import { RemovePromptModal } from 'components/prompts/RemovePromptModal'
import { usePromptDistribution } from 'hooks/usePromptDistribution'

export const PromptDistribution: React.FC = () => {
  const { t } = useTranslation()
  const {
    setPromptData,
    promptDeleted,
    setPromptToDelete,
    activePrompts,
    inactivePrompts,
    showInactive,
    setShowInactive,
    openRemoveModal,
    setOpenRemoveModal,
    error,
    isLoading,
    isSaving,
    totalDistribution,
    removePrompt,
    savePrompts,
  } = usePromptDistribution()

  if (isLoading || isSaving) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <>
      <div className="h-full w-full flex flex-col">
        <div className="diffusion-prompts">
          <div className="hidden md:flex flex-row font-semibold p-4 items-center w-full">
            <div className="w-5/12">{t('prompts.diffusion.prompts')}</div>
            <div className="grid grid-cols-12 gap-10 w-7/12 items-center">
              <div className="col-span-2 ml-10px">
                {t('prompts.diffusion.etat')}
              </div>
              <div className="col-span-3 flex flex-row items-center ml-10px">
                {t('prompts.diffusion.distribution')}
                <span
                  className={`ml-10px text-[18px] ${
                    totalDistribution > 100 || error
                      ? 'text-red-1250'
                      : 'text-blue-1350'
                  }`}
                >
                  {totalDistribution}%
                </span>
              </div>
              <div className="col-span-5">
                {t('prompts.diffusion.evaluationTitre')}
              </div>
              <div className="col-span-2"></div>
            </div>
          </div>
          <div>
            {activePrompts?.map((prompt) => (
              <PromptDistributionRow
                key={prompt.id}
                prompt={prompt}
                setPromptData={setPromptData}
                isDeleted={!!promptDeleted.find((p) => p.id === prompt.id)}
                setOpenModal={setOpenRemoveModal}
                setPromptToDelete={setPromptToDelete}
                error={error}
              />
            ))}
          </div>

          {inactivePrompts &&
            inactivePrompts.length > 0 &&
            activePrompts &&
            activePrompts.length >= 1 && (
              <button
                onClick={() => setShowInactive(!showInactive)}
                className="my-4 text-blue-1350 hover:underline"
              >
                <div className="flex flex-row items-center gap-4">
                  {showInactive ? (
                    <>
                      <EyeClosed />
                      {t('prompts.diffusion.masquerInactif')}
                    </>
                  ) : (
                    <>
                      <EyeOpened />
                      {t('prompts.diffusion.afficherInactif')}
                    </>
                  )}
                </div>
              </button>
            )}

          {showInactive && (
            <div className="">
              {inactivePrompts?.map((prompt) => (
                <PromptDistributionRow
                  key={prompt.id}
                  prompt={prompt}
                  setPromptData={setPromptData}
                  isDeleted={!!promptDeleted.find((p) => p.id === prompt.id)}
                  setOpenModal={setOpenRemoveModal}
                  setPromptToDelete={setPromptToDelete}
                  error={error}
                />
              ))}
            </div>
          )}
        </div>
        <div className="diffusion-button-wrapper">
          <button
            className="md:btn md:default w-full md:w-[400px] btn-mobile"
            onClick={savePrompts}
          >
            {t('prompts.diffusion.submitButton')}
          </button>
        </div>
      </div>
      <RemovePromptModal
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
        handleClick={removePrompt}
      />
    </>
  )
}
