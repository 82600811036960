import { FieldError } from 'react-hook-form'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const FormInput: React.FC<{
  fieldName: string
  register: any
  wrapperClassName?: string
  inputClassName?: string
  wrapperErrorClassName?: string
  error?: FieldError
}> = ({
  fieldName,
  wrapperClassName,
  inputClassName,
  wrapperErrorClassName,
  register,
  error,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`${wrapperClassName ?? ''}`}>
      <input
        name={fieldName}
        className={`${inputClassName ?? ''} ${error ? 'error' : ''}`}
        placeholder={t('global.form.input.placeholder')}
        {...register(fieldName, { required: true })}
      />
      <div
        className={`${wrapperErrorClassName ?? ''} ${error ? 'block' : 'hidden'}`}
      >
        {error?.type === 'required' && (
          <p className="form-error">{t('global.form.error.mandatory')}</p>
        )}
        {error?.type === 'custom' && (
          <p className="form-error">{error.message}</p>
        )}
      </div>
    </div>
  )
}
