import { baseApi } from 'api/base'
import { IFeedbackBody, IFeedbackResponse } from 'interfaces/Feedback'

const FEEDBACK_ENDPOINT = '/feedback'

const feedbackApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    postFeedback: builder.mutation<IFeedbackResponse, IFeedbackBody>({
      query: (feedback) => ({
        url: FEEDBACK_ENDPOINT,
        method: 'POST',
        body: feedback,
      }),
    }),
  }),
})

export const { usePostFeedbackMutation } = feedbackApi
