import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'
import { useCreatePromptForm } from 'hooks/useCreatePromptForm'
import React, { useContext, useState } from 'react'
import { ActivePromptModal } from 'components/prompts/ActivePromptModal'
import { PromptDocumentsSection } from 'components/prompts/PromptDocumentsSection'
import { PromptFormFields } from 'components/prompts/PromptFormFields'
import { PromptFormFieldsExpert } from 'components/prompts/PromptFormFieldsExpert'
import { PromptFormFieldsAssisted } from 'components/prompts/PromptFormFieldsAssisted'
import { ReactComponent as AddDocuments } from 'assets/images/addDocuments.svg'
import { ReactComponent as AddDocumentsDark } from 'assets/images/addDocumentsDark.svg'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { ReactComponent as BackArrowIcon } from 'assets/images/backArrow.svg'

export const PromptForm = () => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [displayDocs, setDisplayDocs] = useState(false)
  const {
    state,
    setters,
    fields,
    promptRef,
    tacheRef,
    addTextToTextarea,
    errors,
    onFormSubmit,
    handleConfirm,
    handleCancel,
    cancel,
    changeMode,
    setValue,
    modeles,
    register,
  } = useCreatePromptForm()
  const returnToForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setDisplayDocs(false)
  }

  if (state.isLoading) {
    return (
      <Loader
        center
        className="h-screen flex items-center justify-center w-full"
      />
    )
  }
  return (
    <>
      <form
        onSubmit={onFormSubmit}
        className="h-full w-full flex flex-col md:text-[13px]"
      >
        <div className="prompt-form-wrapper">
          <div
            className={`prompt-form-fields-content ${displayDocs ? 'hidden' : 'flex'}`}
          >
            {modeles && (
              <PromptFormFields
                nomField={fields.nomField}
                errors={errors}
                assistedMode={state.assistedMode}
                changeMode={changeMode}
                temperatureField={fields.temperatureField}
                setValue={setValue}
                modeleList={modeles}
                register={register}
                modeleField={fields.modeleField}
              />
            )}
            {state.assistedMode ? (
              <PromptFormFieldsAssisted
                tacheField={fields.tacheField}
                roleField={fields.roleField}
                contexteField={fields.contexteField}
                formatField={fields.formatField}
                tacheRef={tacheRef}
                addTextToTextarea={addTextToTextarea}
                errors={errors}
              />
            ) : (
              <PromptFormFieldsExpert
                promptField={fields.promptField}
                promptRef={promptRef}
                addTextToTextarea={addTextToTextarea}
                errors={errors}
              />
            )}
          </div>
          <div
            className={`md:w-[40%] md:block ${displayDocs ? 'block' : 'hidden'}`}
          >
            <PromptDocumentsSection
              documents={state.documents}
              setDocuments={setters.setDocuments}
              newFiles={state.newFiles}
              setNewFiles={setters.setNewFiles}
            />
          </div>
        </div>
        <div className="button-wrapper relative">
          <button
            className="btn cancel w-full md:w-[180px] md:block hidden"
            onClick={cancel}
          >
            {t('global.form.button.cancel')}
          </button>
          <input
            type="submit"
            className={`btn default submit prompt-btn-submit ${displayDocs ? 'hidden' : 'block'}`}
            value={t('prompts.form.button.validate')}
          />
          <button
            className={`btn w-full relative md:hidden h-[60px] bg-gray-1325 rounded-[0] ${displayDocs ? 'block' : 'hidden'}`}
            onClick={(event) => returnToForm(event)}
          >
            <span className="absolute left-[20px] bottom-[20px]">
              <BackArrowIcon className="icon" />
            </span>
            {t('prompts.form.button.promptReturn')}
          </button>
          <div
            className={`md:hidden fixed bottom-3.5 right-3.5 ${!displayDocs ? 'flex' : 'hidden'} flex-row`}
            onClick={() => setDisplayDocs(!displayDocs)}
          >
            {isDarkTheme ? <AddDocumentsDark /> : <AddDocuments />}
          </div>
        </div>
      </form>
      <ActivePromptModal
        setOpen={setters.setOpen}
        open={state.open}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  )
}
