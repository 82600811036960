import { DarkThemeToggle } from 'components/layout/DarkThemeToggle'
import React from 'react'
import { generatePath, useNavigate } from 'react-router'
import { ReactComponent as LogoLauria } from 'assets/images/logoLauria.svg'

export const LayoutHeader = () => {
  const navigate = useNavigate()
  const goToDashboard = () => {
    navigate(generatePath('/'))
  }

  return (
    <div className="layout-header">
      <div>
        <LogoLauria className="logo-lauria" onClick={goToDashboard} />
      </div>
      <DarkThemeToggle />
    </div>
  )
}
