import Keycloak, { KeycloakConfig } from 'keycloak-js'
import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
} from 'constants/general'

const config: KeycloakConfig = {
  realm: KEYCLOAK_REALM,
  url: KEYCLOAK_URL,
  clientId: KEYCLOAK_CLIENT_ID,
}
// eslint-disable-next-line import/prefer-default-export
export const keycloak = new Keycloak(config)

export const updateToken = async () =>
  keycloak.updateToken(10).then(() => keycloak.token || '')
