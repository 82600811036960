import { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const useDarkTheme = () => {
  const context = useContext(DarkThemeContext)
  if (context === undefined) {
    throw new Error('useDarkMode must be used within a DarkModeProvider')
  }
  return context
}
