import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ErrorState {
  code: number | null
}

const initialState: ErrorState = {
  code: null,
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<number>) {
      state.code = action.payload
    },
    clearError(state) {
      state.code = null
    },
  },
})

export const { setError, clearError } = errorSlice.actions
export default errorSlice.reducer
