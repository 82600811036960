import React from 'react'
import {
  toast as originalToast,
  ToastContainer,
  ToastContent,
  ToastOptions,
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const CustomToastContainer = () => (
  <ToastContainer
    closeButton
    hideProgressBar
    position="bottom-right"
    role="alert"
    theme="colored"
  />
)

export const toast = {
  success(content: ToastContent, options?: ToastOptions) {
    originalToast.success(content, {
      icon: false,
      position: 'bottom-right',
      bodyClassName: 'whitespace-pre-line',
      ...options,
    })
  },
  error(content: ToastContent, options?: ToastOptions) {
    originalToast.error(content, { position: 'bottom-right', ...options })
  },
  warning(content: ToastContent, options?: ToastOptions) {
    originalToast.warning(content, {
      position: 'bottom-right',
      bodyClassName: 'text-xs',
      ...options,
    })
  },
}
