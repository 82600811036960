import { IDocument } from 'interfaces/Document'
import { PromptDocuments } from 'components/prompts/PromptDocuments'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface PromptDocumentsSectionProps {
  documents: IDocument[]
  setDocuments: React.Dispatch<React.SetStateAction<IDocument[]>>
  newFiles: File[]
  setNewFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export const PromptDocumentsSection: React.FC<PromptDocumentsSectionProps> = ({
  documents,
  setDocuments,
  newFiles,
  setNewFiles,
}) => {
  const { t } = useTranslation()
  return (
    <div className="prompt-doc-container">
      <div className="title">
        <span className="font-medium text-[18px]">
          {t('prompts.documentsTitle')}
        </span>
        <span>{t('prompts.docLimit')}</span>
      </div>
      <div className="md:flex-grow w-full md:px-[45px] px-0">
        <PromptDocuments
          documents={documents}
          setDocuments={setDocuments}
          newFiles={newFiles}
          setNewFiles={setNewFiles}
          withDelete
        />
      </div>
    </div>
  )
}
