import { ReactComponent as Avatar } from 'assets/images/avatar.svg'
import { ReactComponent as AvatarDarkMobile } from 'assets/images/avatarDarkMobile.svg'
import { FC, useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const UserCardMobile: FC<{ username: string }> = ({ username }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="card-user-mobile">
      {isDarkTheme ? (
        <AvatarDarkMobile />
      ) : (
        <Avatar width="45px" height="45px" />
      )}
      <div className="username">{username}</div>
    </div>
  )
}
