import React from 'react'
import { FieldLabel } from 'components/common/FieldLabel'
import { FieldError } from 'react-hook-form'
import { FormInput } from 'components/common/FormInput'

export const ApplicationFormInput: React.FC<{
  label: string
  fieldName: string
  error?: FieldError
  register: any
}> = ({ label, fieldName, error, register }) => {
  return (
    <div className="md:flex md:flex-col">
      <FieldLabel
        className="h-[45px] w-[300px] flex items-center"
        label={label}
        required
      />
      <FormInput
        fieldName={fieldName}
        register={register}
        error={error}
        wrapperClassName="md:w-[300px]"
        inputClassName="w-full form-input mb-[10px]"
        wrapperErrorClassName="h-[30px]"
      />
    </div>
  )
}
