import { ITag } from 'interfaces/Tag'
import { ReactComponent as EditIcon } from 'assets/images/editIcon.svg'
import { ReactComponent as DeleteIconDark } from 'assets/images/deleteDark.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { useTranslation } from 'react-i18next'
import { SetStateAction, useContext, useState } from 'react'
import { CurrentTagContext } from 'contexts/CurrentTagContext'
import { FormTagModal } from 'components/tags/FormTagModal'
import { TagForm } from 'components/tags/TagForm'
import { DeleteTagModal } from 'components/tags/DeleteTagModal'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const TagsOptions: React.FC<{
  tags: ITag[]
  setHidden: (value: SetStateAction<boolean>) => void
}> = ({ tags, setHidden }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [editTag, setEditTag] = useState<ITag | null>(null)
  const [deleteTag, setDeleteTag] = useState<ITag | null>(null)
  const { currentTag, setCurrentTag } = useContext(CurrentTagContext)
  const displayTagApps = (tag: ITag) => {
    if (tag?.applicationsCount && tag?.applicationsCount > 0) {
      setCurrentTag(tag)
      setHidden(true)
    }
  }
  const handleEdit = (tag: ITag) => {
    setEditTag(tag)
    setOpenEdit(true)
  }

  const handleDelete = (tag: ITag) => {
    setDeleteTag(tag)
    setOpenDelete(true)
  }

  return (
    <>
      <div className="md:w-[370px] w-full tag-menu">
        <div className="tags-options-list-wrapper">
          <div className="tags-options-list">
            {tags.map((tag, index) => {
              return (
                <div
                  key={index}
                  className={`tags-option ${tag.id === currentTag?.id ? 'selected' : ''}`}
                >
                  <div
                    className={`tag-name ${tag.id === currentTag?.id ? 'selected' : ''}`}
                    onClick={() => displayTagApps(tag)}
                  >
                    <div className="flex-grow">
                      {tag.id && (
                        <>
                          <span className="font-medium">{tag.nomTag}</span>
                          <br />
                        </>
                      )}
                      <span className="font-light">
                        {t('tags.application', {
                          count: tag.applicationsCount,
                        })}
                      </span>
                      {!tag.id && (
                        <>
                          <br />
                          <span className="font-light">{t('tags.noTags')}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    {tag.id && (
                      <EditIcon
                        className={`cursor-pointer tag-icon text-white ${tag.id === currentTag?.id ? 'md:text-gray-1325' : ''} dark:text-blue-1350`}
                        onClick={() => handleEdit(tag)}
                      />
                    )}
                  </div>
                  <div className="flex">
                    {tag.id &&
                      (isDarkTheme || tag.id === currentTag?.id ? (
                        <DeleteIconDark
                          className={`w-[20px] text-white dark:text-black cursor-pointer ${tag.id === currentTag?.id && !isDarkTheme ? 'hidden' : 'md:block'}`}
                          onClick={() => handleDelete(tag)}
                        />
                      ) : (
                        <DeleteIcon
                          className="w-[20px] text-white dark:text-gray-1350 cursor-pointer"
                          onClick={() => handleDelete(tag)}
                        />
                      ))}
                    {tag.id === currentTag?.id && !isDarkTheme && (
                      <DeleteIcon
                        className="w-[20px] text-white dark:text-gray-1350 cursor-pointer md:hidden block"
                        onClick={() => handleDelete(tag)}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="trait"></div>
          <div className="tag-form-wrapper">
            <TagForm />
          </div>
        </div>
      </div>
      <FormTagModal open={openEdit} setOpen={setOpenEdit} tag={editTag} />
      <DeleteTagModal
        open={openDelete}
        setOpen={setOpenDelete}
        tag={deleteTag}
      />
    </>
  )
}
