import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { setError } from 'store/slices/error'

export const apiErrorMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action: any) => {
    if (isRejectedWithValue(action)) {
      const status = action.payload?.status
      if (status === 404) {
        dispatch(setError(404))
      } else if (status === 500 || status === 'FETCH_ERROR') {
        dispatch(setError(500))
      }
    }

    return next(action)
  }
