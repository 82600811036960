import { FieldLabel } from 'components/common/FieldLabel'
import { FormInput } from 'components/common/FormInput'
import React from 'react'
import { useEditTag } from 'hooks/useEditTag'
import { ITag } from 'interfaces/Tag'
import { useTranslation } from 'react-i18next'

export const TagForm: React.FC<{
  setModalOpen?: (value: boolean) => void
  cancelAction?: (event: any) => void
  tag?: ITag | null
}> = ({ setModalOpen, cancelAction, tag }) => {
  const { register, isValid, onSubmit, errors } = useEditTag(setModalOpen, tag)
  const { t } = useTranslation()
  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="tag-form">
        <div className="flex flex-col">
          <FieldLabel
            className={`h-[45px] ${tag ? 'w-[120px]' : 'w-[90px]'} flex items-center`}
            label={t('tags.edit.intituleLabel')}
            required={!!tag}
          />
          <FormInput
            fieldName="intitule"
            error={errors.intitule}
            register={register}
            wrapperClassName={`w-full ${tag ? 'md:mr-[40px]' : 'md:mr-[60px]'}`}
            inputClassName="w-full form-input"
          />
        </div>
        {tag ? (
          <div
            className={`text-right self-end w-full md:block ${isValid ? '' : 'hidden'} mt-[30px]`}
          >
            <button className="btn cancel hidden" onClick={cancelAction}>
              {t('global.form.button.cancel')}
            </button>
            <input
              type="submit"
              className="btn default ml-30px w-full md:w-[250px]"
              value={t('tags.form.edit.button')}
            />
          </div>
        ) : (
          <div className="w-full">
            <input
              type="submit"
              className="btn default w-full mt-[15px]"
              value={t('tags.form.create.button')}
            />
          </div>
        )}
      </div>
    </form>
  )
}
