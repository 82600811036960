import React from 'react'
import { FieldLabel } from 'components/common/FieldLabel'

export const ApplicationFormInputReadonly: React.FC<{
  label: string
  value?: string
}> = ({ label, value }) => {
  return (
    <div className="md:flex md:flex-col">
      <FieldLabel
        className="h-[45px] w-[300px] flex items-center"
        label={label}
      />
      <div className="flex h-[45px] items-center">{value}</div>
    </div>
  )
}
