import { IPrompt } from 'interfaces/Prompt'
import Card from 'components/common/Card'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { ReactComponent as PromptIconBanner } from 'assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as PromptIconBannerDark } from 'assets/images/prompts/promptIconBannerDark.svg'

export const PromptContentCard: React.FC<{ prompt: IPrompt }> = ({
  prompt,
}) => {
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <Card className="prompt-card w-full" withHoverStyle={false}>
      <div className="card-prompt-header-wrapper pl-[25px] pr-[35px]">
        <div className="card-prompt-header-content py-[20px]">
          {isDarkTheme ? <PromptIconBannerDark /> : <PromptIconBanner />}
          <div className="ml-[20px] uppercase font-medium">
            <span>{prompt.nomPrompt}</span>
          </div>
        </div>
      </div>
      <div className="prompt-card-prompt pl-[25px]">{prompt.prompt}</div>
    </Card>
  )
}
