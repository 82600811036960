import React from 'react'

export const Tooltip: React.FC<
  React.PropsWithChildren<{
    text: string
    classname?: string
  }>
> = ({ children, text }) => {
  return (
    <div className="flex flex-row items-center relative group">
      {children}
      <div className="tooltip">{text}</div>
    </div>
  )
}
