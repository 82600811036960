import ModalWrapper from 'components/common/ModalWrapper'
import { ModalHeader } from 'components/common/ModalHeader'
import { ModalBody } from 'components/common/ModalBody'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivePromptModalContent } from 'components/prompts/ActivePromptModalContent'

export const ActivePromptModal: React.FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  handleCancel: () => void
  handleConfirm: () => void
}> = ({ open, setOpen, handleCancel, handleConfirm }) => {
  const hide = () => {
    setOpen(false)
  }
  const { t } = useTranslation()

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-activation' }}
      onClose={hide}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader>{t('prompts.activePromptModalTitle')}</ModalHeader>
      <ModalBody>
        <ActivePromptModalContent
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      </ModalBody>
    </ModalWrapper>
  )
}
