import React, { useContext, useState } from 'react'
import { ReactComponent as SendIcon } from 'assets/images/sendIcon.svg'
import { useTranslation } from 'react-i18next'
import { IUsage } from 'interfaces/Usage'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
export const MessageInput: React.FC<{
  current: IUsage
  setMessage: (input: string) => void
  onSend: (message: string) => void
}> = ({ current, setMessage, onSend }) => {
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const svgPath = require(`assets/images/${current.logo}`)
  const svgPathDark = require(`assets/images/${current.logoDark}`)
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [placeholder, setPlaceholder] = useState(
    t(`accueil.usages.${current.usageKey}.placeholder`)
  )
  const handleFocus = () => {
    setIsFocused(true)
    setPlaceholder('')
  }
  const handleBlur = () => {
    setIsFocused(false)
    setPlaceholder(t(`accueil.usages.${current.usageKey}.placeholder`))
  }
  const handleSend = () => {
    if (input.trim() !== '') {
      setMessage(input)
      onSend(input)
      setInput('')
    }
  }
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.ctrlKey || e.shiftKey) {
        e.preventDefault()
        setInput((prev) => prev + '\n')
      } else {
        e.preventDefault()
        handleSend()
      }
    }
  }

  const isDisabled = input.trim() === ''

  return (
    <div className="message-input relative">
      <div className="flex flex-row w-full items-center justify-center">
        {isFocused && (
          <img
            className="md:block hidden h-[60px] mr-[15px]"
            src={isDarkTheme ? svgPathDark : svgPath}
            alt="Icon"
          />
        )}
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSend} disabled={isDisabled}>
          <SendIcon
            className={`${isDisabled ? 'text-gray-1200 dark:text-gray-1250' : 'text-blue-1400 dark:text-purple-1700 hover:cursor-pointer'}`}
          />
        </button>
      </div>
      <div className="trait"></div>
    </div>
  )
}
