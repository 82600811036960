import React, { ReactNode } from 'react'
import { DarkModeProvider } from 'contexts/DarkThemeContext'
import { LayoutHeader } from 'components/layout/LayoutHeader'

interface LayoutWrapperProps {
  children: ReactNode
  layoutClassName?: string
}

export const LayoutWrapper: React.FC<LayoutWrapperProps> = ({
  children,
  layoutClassName = 'layout-container',
}) => {
  return (
    <DarkModeProvider>
      <div className={layoutClassName}>
        <LayoutHeader />
        {children}
      </div>
    </DarkModeProvider>
  )
}
