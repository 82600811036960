import { baseApi } from 'api/base'
import { generatePath } from 'react-router'
import { ITag } from 'interfaces/Tag'

const TAGS_ENDPOINT = '/tags'
const TAG_ENDPOINT = `${TAGS_ENDPOINT}/:idTag`
const TAGS_WITH_APPLICATIONS_ENDPOINT = '/tags-with-applications'
const tagApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTags: builder.query<ITag[], void>({
      query: () => generatePath(TAGS_ENDPOINT),
    }),
    getAllTagsWithApplications: builder.query<ITag[], void>({
      query: () => generatePath(TAGS_WITH_APPLICATIONS_ENDPOINT),
      providesTags: (result: ITag[] | undefined) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: 'TagsWithApps', id }) as const
              ),
              { type: 'TagsWithApps', id: 'LIST' },
            ]
          : [{ type: 'TagsWithApps', id: 'LIST' }],
    }),
    createTag: builder.mutation<
      ITag,
      {
        nomTag: string
      }
    >({
      query: ({ nomTag }) => ({
        url: generatePath(TAGS_ENDPOINT),
        method: 'POST',
        body: { nomTag, statut: 'visible' },
      }),
      invalidatesTags: [{ type: 'TagsWithApps', id: 'LIST' }],
    }),
    updateTag: builder.mutation<
      ITag,
      {
        nomTag: string
        idTag: number
      }
    >({
      query: ({ nomTag, idTag }) => ({
        url: generatePath(TAG_ENDPOINT, {
          idTag: idTag.toString(),
        }),
        method: 'PATCH',
        body: { nomTag },
      }),
      invalidatesTags: (result, error, { idTag }) => [
        { type: 'TagsWithApps', idTag },
      ],
    }),
    deleteTag: builder.mutation<void, number>({
      query(idTag) {
        return {
          url: generatePath(TAG_ENDPOINT, {
            idTag: idTag.toString(),
          }),
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'TagsWithApps', id }],
    }),
  }),
})

export const {
  useGetAllTagsQuery,
  useGetAllTagsWithApplicationsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagApi
export { tagApi }
