import React, { useContext } from 'react'
import { IApplication } from 'interfaces/Application'
import { ApplicationCardMobile } from 'components/dashboard/ApplicationCardMobile'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'
import { groupByService } from 'utils/service'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const ApplicationCardListMobile: React.FC<{
  applications: IApplication[]
}> = ({ applications }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const navigate = useNavigate()
  const appsByService = groupByService(applications)

  const redirectApp = (appId: number) => {
    navigate(generatePath(ROUTE_PATHS.PROMPTS, { idApplication: appId }))
  }
  return (
    <div className="w-full">
      {Object.entries(appsByService).map(([serviceName, { service, apps }]) => (
        <div key={serviceName} className="mb-[30px]">
          <div className="font-semibold text-base mb-[15px] flex flex-row items-center">
            <img
              width="33px"
              alt=""
              className="mr-[10px]"
              src={isDarkTheme ? service.svgPathDark : service.svgPath}
            />
            <span
              style={{ color: isDarkTheme ? service.colorDark : service.color }}
            >
              {serviceName}
            </span>
          </div>
          <div className="flex flex-row w-full overflow-x-scroll">
            {apps.map((app) => {
              return (
                <ApplicationCardMobile
                  key={app.id}
                  application={app}
                  handleClick={() => redirectApp(app.id)}
                />
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}
