import React, { FC, ReactNode } from 'react'
import 'assets/styles/card.css'

interface CardProps {
  children: ReactNode
  onClick?: () => void
  className?: string
  style?: any
  withHoverStyle?: boolean
}

const Card: FC<CardProps> = ({
  children,
  onClick,
  className,
  style,
  withHoverStyle = true,
}) => {
  return (
    <div
      className={`card ${className ?? ''} ${withHoverStyle ? 'with-hover' : ''}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  )
}

export default Card
