import { useParams } from 'react-router-dom'
import { useGetPromptQuery } from 'api/prompt'
import { skipToken } from '@reduxjs/toolkit/query'
import Loader from 'components/common/Loader'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'
import React from 'react'
import { PromptNavigation } from 'components/prompts/PromptNavigation'
import { useSelector } from 'react-redux'
import { promptLayout } from 'store/slices/promptLayout'
import { PromptContent } from 'components/prompts/PromptContent'

export const Prompt = () => {
  const { t } = useTranslation()
  const { idPrompt } = useParams()
  const navigate = useNavigate()
  const { application } = useSelector(promptLayout)
  const promptId = idPrompt ? parseInt(idPrompt, 10) : undefined

  const {
    data: prompt,
    isLoading: isLoadingPrompt,
    isFetching: isFetchingPrompt,
  } = useGetPromptQuery(promptId ? { promptId } : skipToken)

  if (isLoadingPrompt || isFetchingPrompt) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  const handleClick = () => {
    navigate(
      generatePath(ROUTE_PATHS.PROMPT_EDIT, {
        idApplication: application?.id,
        idPrompt: prompt?.id,
      })
    )
  }

  if (!application || !prompt) {
    return null
  }

  return (
    <>
      <PromptNavigation application={application} />

      <div className="prompt-content">
        {prompt && (
          <>
            <div className="flex flex-col w-full h-full">
              <PromptContent prompt={prompt} />
              <div className="button-wrapper md:flex">
                <button
                  className="btn default w-full md:w-[230px] md:rounded-[5px] rounded-[0] md:h-[50px] h-[60px]"
                  onClick={handleClick}
                >
                  {t('prompts.edit')}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
