import { useParams } from 'react-router-dom'
import { usageParams } from 'constants/usage'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { Trans, useTranslation } from 'react-i18next'

export const AccueilHeader = () => {
  const { usage } = useParams()
  const current = usageParams.find((param) => param.usageKey === usage)
  if (!current) {
    return null
  }
  const svgPath = require(`assets/images/${current.logo}`)
  const svgPathDark = require(`assets/images/${current.logoDark}`)
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { t } = useTranslation()

  return (
    current && (
      <div className="accueil-page-header">
        <img
          className="md:h-[90px] h-[60px] md:mr-[50px] mr-[20px] ml-[40px] md:ml-0"
          src={isDarkTheme ? svgPathDark : svgPath}
          alt="Icon"
        />
        <div className="flex flex-col mr-30px md:mr-0">
          <div className="title">
            <Trans
              i18nKey={`accueil.usages.${current.usageKey}.title`}
              components={{ big: <span className="text-[40px]" /> }}
            />
          </div>
          <div className="md:mt-15px md:text-xl text-base">
            {t(`accueil.usages.${current.usageKey}.text`)}
          </div>
        </div>
      </div>
    )
  )
}
