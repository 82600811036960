import React, { SetStateAction, useContext } from 'react'
import { CurrentTagContext } from 'contexts/CurrentTagContext'
import { useGetAllApplicationsQuery } from 'api/application'
import Loader from 'components/common/Loader'
import { ApplicationCard } from 'components/dashboard/ApplicationCard'
import { generatePath, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATHS } from 'constants/routePath'
import { ReactComponent as TagIcon } from 'assets/images/tagIcone.svg'
import { ReactComponent as BackArrowIcon } from 'assets/images/backArrow.svg'

export const TagsAppsList: React.FC<{
  setHidden: (value: SetStateAction<boolean>) => void
}> = ({ setHidden }) => {
  const { currentTag } = useContext(CurrentTagContext)
  const {
    data: applications,
    isFetching,
    isLoading,
  } = useGetAllApplicationsQuery(
    currentTag?.id ? { tags: [currentTag.id] } : { emptyTag: true }
  )
  const navigate = useNavigate()
  const { t } = useTranslation()

  const redirectApp = (appId: number) => {
    navigate(generatePath(ROUTE_PATHS.PROMPTS, { idApplication: appId }))
  }

  if (isLoading || isFetching) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }
  if (!applications) {
    return null
  }

  return (
    <div className="md:px-[30px] bg-white-150 dark:bg-black h-full w-full overflow-auto">
      <div className="tag-title-list">
        <div className="tag-title admin md:px-0 px-30px mb-10px">
          <TagIcon className="md:hidden flex tag-icon mr-[20px]" />
          {t('tags.appTitle', {
            title: currentTag?.id ? currentTag.nomTag : t('tags.noTags'),
          })}
        </div>
        <div className="trait md:block hidden"></div>
        <div className="navigation" onClick={() => setHidden(false)}>
          <BackArrowIcon className="icon mr-10px" />
          <span>{t('prompts.goToOptions')}</span>
        </div>
      </div>
      <div className="card-list tag-mobile">
        {applications.map((app) => {
          return (
            <div key={app.id} className="flex flex-col">
              <ApplicationCard
                application={app}
                handleClick={() => redirectApp(app.id)}
              />
              <div className="w-[260px] h-auto flex mt-2 mx-auto md:mx-0">
                <div className="flex flex-col ">
                  {(app.tags?.filter((tag) => tag.id !== currentTag?.id)
                    .length ?? 0) > 0 && (
                    <span className="mr-1.5 font-light text-[12px] text-gray-1250 pb-2">
                      {t('tags.otherTags')}
                    </span>
                  )}
                  <div className="flex flex-wrap">
                    {app.tags
                      ?.filter((tag) => tag.id !== currentTag?.id)
                      .map((tag) => {
                        return (
                          <div key={tag.id}>
                            <div className="tag-button mr-1 mb-1">
                              <span className="text-app">{tag.nomTag}</span>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
