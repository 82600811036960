import { TagsOptions } from 'components/tags/TagsOptions'
import { useGetAllTagsWithApplicationsQuery } from 'api/tag'
import Loader from 'components/common/Loader'
import { useContext, useEffect, useState } from 'react'
import { CurrentTagContext } from 'contexts/CurrentTagContext'
import { TagsAppsList } from 'components/tags/TagsAppsList'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TagIcon } from 'assets/images/tagIcone.svg'

export const TagsAdmin = () => {
  const {
    data: tags,
    isLoading,
    isFetching,
  } = useGetAllTagsWithApplicationsQuery()
  const { setCurrentTag } = useContext(CurrentTagContext)
  const [hidden, setHidden] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    if (tags && tags.length > 0) {
      setCurrentTag(tags[0])
    }
  }, [tags])
  if (isLoading || isFetching) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader center />
      </div>
    )
  }

  if (!tags) {
    return null
  }

  return (
    tags && (
      <div className="tags-admin">
        <div
          className={`tags-options-wrapper flex-col ${hidden ? 'md:flex hidden' : 'flex'}`}
        >
          <div className="tag-title options">
            <TagIcon className="md:hidden flex tag-icon mr-[20px]" />
            {t('tags.optionTitle')}
          </div>
          <TagsOptions tags={tags} setHidden={setHidden} />
        </div>
        <div
          className={`md:bg-white bg-white-150 dark:bg-black h-full w-full flex-grow overflow-auto ${hidden ? 'flex' : 'md:flex hidden'}`}
        >
          <TagsAppsList setHidden={setHidden} />
        </div>
      </div>
    )
  )
}
