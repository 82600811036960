import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'constants/general'
import { updateToken } from 'keycloak'

const baseQueryWithAuth = async (args: any, api: any, extraOptions: any) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: async (headers) => {
      const token = await updateToken()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  })

  return baseQuery(args, api, extraOptions)
}

export const baseApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Application', 'Prompt', 'TagsWithApps', 'Conversation'],
  endpoints: () => ({}),
  keepUnusedDataFor: 5,
})
