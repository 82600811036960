import Keycloak from 'keycloak-js'
import React, { createContext } from 'react'

export type KeycloakContextType = {
  keycloakInstance: Keycloak | null
  setKeycloakInstance: React.Dispatch<React.SetStateAction<Keycloak>>
  initialized: boolean
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>
}

export const KeycloakContext = createContext<KeycloakContextType>({
  keycloakInstance: null,
  setKeycloakInstance: () => {},
  initialized: false,
  setInitialized: () => {},
})
