import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import React from 'react'

interface FormSelectProps {
  fieldName: string
  options: { value: any; label: string }[]
  error?: FieldError
  register?: any
  wrapperClassName?: string
  selectClassName?: string
  wrapperErrorClassName?: string
  required?: boolean
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  defaultValue?: any
  value?: any
  isLoading?: boolean
  displayDefaultOption?: boolean
}

export const FormSelect: React.FC<FormSelectProps> = ({
  fieldName,
  options,
  error,
  register = () => {},
  wrapperClassName = 'md:w-[300px]',
  selectClassName = 'w-full form-input pr-30px',
  wrapperErrorClassName = 'h-[30px]',
  placeholder = '',
  required = false,
  onChange,
  isLoading = false,
  value,
  defaultValue = '',
  displayDefaultOption = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`${wrapperClassName}`}>
      <select
        {...(onChange && { onChange })}
        name={fieldName}
        {...(value && { value })}
        {...(!value && { defaultValue })}
        className={`${selectClassName} ${error ? 'error' : ''}`}
        {...register(fieldName, { required })}
      >
        {isLoading ? (
          <>
            <option value="" disabled hidden>
              {placeholder}
            </option>
            <option>{t('global.form.select.loading')}</option>
          </>
        ) : (
          <>
            <option
              value=""
              disabled={!displayDefaultOption}
              hidden={!displayDefaultOption}
            >
              {placeholder}
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </>
        )}
      </select>
      <div className={`${error && wrapperErrorClassName}`}>
        {error?.type === 'required' && (
          <p className="form-error">{t('global.form.error.mandatory')}</p>
        )}
        {error?.type === 'custom' && (
          <p className="form-error">{error.message}</p>
        )}
      </div>
    </div>
  )
}
