import React, { SetStateAction } from 'react'
import { ITag } from 'interfaces/Tag'
import { ModalHeader } from 'components/common/ModalHeader'
import ModalWrapper from 'components/common/ModalWrapper'
import { ModalBody } from 'components/common/ModalBody'
import { useTranslation } from 'react-i18next'
import { TagForm } from 'components/tags/TagForm'

export const FormTagModal: React.FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  tag: ITag | null
}> = ({ open, setOpen, tag }) => {
  const { t } = useTranslation()
  const hide = (event: any) => {
    event.preventDefault()
    setOpen(false)
  }

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-tag' }}
      onClose={() => setOpen(false)}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader titleClassName="modal-title mt-[30px]">
        {t('tags.edit.title')}
      </ModalHeader>
      <ModalBody className="my-[30px] ml-[30px]">
        <TagForm cancelAction={hide} setModalOpen={setOpen} tag={tag} />
      </ModalBody>
    </ModalWrapper>
  )
}
