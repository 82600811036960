import { ReactComponent as IAAvatar } from 'assets/images/iaAvatar.svg'
import { ReactComponent as IAAvatarDark } from 'assets/images/iaAvatarDark.svg'
import { Fragment, useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import StarRating from 'components/common/StarRating'
import { useTranslation } from 'react-i18next'
import { useHandleRateMessage } from 'hooks/useHandleRateMessage'
import { IMessage } from 'store/slices/conversationSlice'
import Loader from 'components/common/Loader'
export const IAMessage: React.FC<{
  message: IMessage
  first?: boolean
  isLoading?: boolean
  application: string
}> = ({ message, first = false, isLoading = false, application }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { t } = useTranslation()
  const { handleRateMessage, isLoading: isLoadingRate } = useHandleRateMessage(
    application,
    message.id ?? 0
  )
  return (
    <div
      className={`ia-message message-container ${first ? 'md:mt-60px' : ''}`}
    >
      <div className="flex flex-row">
        <div className="avatar">
          {isDarkTheme ? (
            <IAAvatarDark className="md:w-[60px] w-[30px]" />
          ) : (
            <IAAvatar className="md:w-[60px] w-[30px]" />
          )}
        </div>
        <div className="flex flex-col">
          <div className={`message ${first ? '' : 'mb-10px'}`}>
            <div className="message-content">
              {isLoading ? (
                <div className="dots text-3xl h-[30px]"></div>
              ) : (
                <div className="message-text">{message?.content}</div>
              )}
            </div>
          </div>
          {!first && !isLoading && (
            <div className="flex flex-row items-center">
              {!isLoadingRate ? (
                <>
                  <span className="text-blue-1400 mr-10px">
                    {message?.feedback
                      ? t('accueil.merci')
                      : t('accueil.evaluation')}
                  </span>
                  <StarRating
                    initialRate={message?.feedback}
                    onClick={handleRateMessage}
                  />
                </>
              ) : (
                <Loader size="small" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
