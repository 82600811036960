import { useTranslation } from 'react-i18next'
import { AccueilCard } from 'components/accueil/AccueilCard'
import { usageParams } from 'constants/usage'
import { resetAllConversations } from 'store/slices/conversationSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

export const Accueil = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetAllConversations())
  }, [])

  return (
    <div className="w-full dark:bg-gray-1600 h-full">
      <div className="header-accueil">
        <div className="text">{t('accueil.textHeader')}</div>
      </div>
      <div className="cards-wrapper">
        {usageParams.map((param, index) => (
          <AccueilCard key={index} {...param} />
        ))}
      </div>
      <div className="cards-wrapper-mobile">
        {usageParams.map((param, index) => (
          <AccueilCard key={index} {...param} isMobile />
        ))}
      </div>
    </div>
  )
}
