import { CurrentTagProvider } from 'contexts/CurrentTagContext'
import { TagsAdmin } from 'components/tags/TagsAdmin'

export const Tags = () => {
  return (
    <CurrentTagProvider>
      <TagsAdmin />
    </CurrentTagProvider>
  )
}
