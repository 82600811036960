import React, { createContext, useState, ReactNode } from 'react'
import { ITag } from 'interfaces/Tag'
interface ICurrentTagContext {
  currentTag: ITag | null
  setCurrentTag: (value: ITag) => void
}

export const CurrentTagContext = createContext<ICurrentTagContext>({
  currentTag: null,
  setCurrentTag: () => {},
})

export const CurrentTagProvider = ({ children }: { children: ReactNode }) => {
  const [currentTag, setCurrentTag] = useState<ITag | null>(null)

  return (
    <CurrentTagContext.Provider
      value={{
        currentTag,
        setCurrentTag,
      }}
    >
      {children}
    </CurrentTagContext.Provider>
  )
}
