import React, { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { ReactComponent as AddIcone } from 'assets/images/addIcone.svg'
import { ReactComponent as AddIconeDark } from 'assets/images/addIconeDark.svg'

export const AddButton: React.FC<{ onClick?: () => void }> = ({
  onClick = () => {},
}) => {
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div
      className="relative  h-[30px] w-[30px] overflow-hidden rounded-full"
      onClick={onClick}
    >
      <div className="add-icone-fond group-hover:bg-blue-1050 group-hover:dark:bg-gray-1600"></div>
      {!isDarkTheme && (
        <>
          <AddIcone className="add-icone group-hover:hidden" />
          <AddIconeDark className="add-icone hidden group-hover:block" />
        </>
      )}
      {isDarkTheme && (
        <AddIconeDark className="add-icone dark:group-hover:text-blue-1350 " />
      )}
    </div>
  )
}
