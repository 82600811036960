import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import { apiErrorMiddleware } from 'api/middleware/apiErrorMiddleware'
import errorReducer from 'store/slices/error'
import { baseApi } from 'api/base'
import promptLayoutSlice from 'store/slices/promptLayout'
import storage from 'redux-persist/lib/storage'
import conversationReducer from 'store/slices/conversationSlice'
import { setupListeners } from '@reduxjs/toolkit/query'

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, conversationReducer)
const store = configureStore({
  reducer: {
    error: errorReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [promptLayoutSlice.name]: promptLayoutSlice.reducer,
    conversation: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware, apiErrorMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
setupListeners(store.dispatch)
export default store
