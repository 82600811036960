import ModalWrapper from 'components/common/ModalWrapper'
import { ModalHeader } from 'components/common/ModalHeader'
import { ModalBody } from 'components/common/ModalBody'
import { FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

export const RemovePromptModal: FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  handleClick: () => void
}> = ({ open, setOpen, handleClick }) => {
  const hide = () => {
    setOpen(false)
  }
  const { t } = useTranslation()

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-remove-prompt' }}
      onClose={hide}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader>{t('prompts.modalRemovePrompt.title')}</ModalHeader>
      <ModalBody>
        <div className="flex flex-col px-[30px]">
          <span>{t('prompts.modalRemovePrompt.text1')}</span>
          <span>{t('prompts.modalRemovePrompt.text2')}</span>
          <div className="text-right mt-[30px] self-end">
            <button className="btn cancel hidden" onClick={hide}>
              {t('global.form.button.cancel')}
            </button>
            <button
              className="btn default w-full md:w-auto ml-[20px]"
              onClick={handleClick}
            >
              {t('prompts.modalRemovePrompt.submit')}
            </button>
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}
