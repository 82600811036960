import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useMatch } from 'react-router'
import { ReactComponent as BackArrowIcon } from 'assets/images/backArrow.svg'
import { NavLink, useParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/routePath'
import { IApplication } from 'interfaces/Application'
import { ReactComponent as PromptIconBanner } from 'assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as PromptIconBannerDark } from 'assets/images/prompts/promptIconBannerDark.svg'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

interface PromptNavigationProps {
  application: IApplication
}

export const PromptNavigation: React.FC<PromptNavigationProps> = ({
  application,
}) => {
  const { t } = useTranslation()
  const isEditPromptPage = useMatch(ROUTE_PATHS.PROMPT_EDIT)
  const { idPrompt } = useParams()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const promptStatus = application.prompts.find(
    (prompt) => prompt.id === Number(idPrompt)
  )?.actif
    ? 'actif'
    : 'inactif'

  return (
    <div className="prompt-navigation">
      <div className="flex flex-row h-full items-center w-full flex-1">
        <div className="flex flex-col w-full">
          <h3 className="md:flex hidden">{t('prompts.title')}</h3>
          <div className="md:hidden flex items-center">
            <div className="flex flex-row items-center mx-auto mb-10px">
              {isDarkTheme ? <PromptIconBannerDark /> : <PromptIconBanner />}
              <div className="ml-[20px] prompt-statut">
                <span>{t('prompts.title')}</span>
                <div className={`${promptStatus}`}>{promptStatus}</div>
              </div>
            </div>
          </div>
          {isEditPromptPage ? (
            <NavLink
              to={generatePath(ROUTE_PATHS.PROMPT, {
                idApplication: application.id,
                idPrompt: idPrompt,
              })}
              className="navLink"
            >
              <BackArrowIcon className="icon" />
              <span>{t('prompts.goToPrompt')}</span>
            </NavLink>
          ) : (
            <NavLink
              to={generatePath(ROUTE_PATHS.PROMPTS, {
                idApplication: application.id,
              })}
              className="navLink"
            >
              <BackArrowIcon className="icon" />
              <span>{t('prompts.goToApplication')}</span>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  )
}
