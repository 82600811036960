import { ReactComponent as Error404Logo } from 'assets/images/error404.svg'
import { ReactComponent as Error404LogoDark } from 'assets/images/error404Dark.svg'
import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const Error404 = () => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="w-full flex flex-col items-center h-full pt-60px">
      {isDarkTheme ? <Error404LogoDark /> : <Error404Logo />}
      <div className="mt-[20px] text-gray-1350 text-2xl dark:text-gray-1250">
        {t('error404')}
      </div>
    </div>
  )
}
