import Card from 'components/common/Card'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from 'assets/images/plusIcon.svg'
import { ReactComponent as NewApp } from 'assets/images/newApp.svg'
import { ReactComponent as NewAppDark } from 'assets/images/newAppDark.svg'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const NewApplicationCard: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <Card
      onClick={onClick}
      className="w-[260px] h-[140px] group md:mx-0 mx-auto"
    >
      <div className="new-card">
        <div className="new-card-app-header-wrapper">
          <div className="new-card-app-header group-hover:bg-white group-hover:dark:bg-gray-1600">
            {isDarkTheme ? <NewAppDark /> : <NewApp />}
          </div>
        </div>
        <div className="new-card-content">
          <div className="flex flex-row h-45px items-center">
            <PlusIcon />
            <span className="ml-10px font-medium text-xl">
              {t('services.newApp')}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}
