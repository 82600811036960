import { IApplication } from 'interfaces/Application'
import { NewApplicationCard } from 'components/dashboard/NewApplicationCard'
import React from 'react'
import { ApplicationCardList } from 'components/dashboard/ApplicationCardList'
import { ApplicationCardListMobile } from 'components/dashboard/ApplicationCardListMobile'
import { useTranslation } from 'react-i18next'
import Loader from 'components/common/Loader'

export const ApplicationCardListWrapper: React.FC<{
  applications: IApplication[]
  openModal: () => void
  isLoading: boolean
  filtersApplied: boolean
}> = ({ applications, openModal, isLoading, filtersApplied }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <Loader pageCenter />
  }

  return (
    <div className="app-list-wrapper">
      <div className="header">
        {t('services.myApp')}
        <div className="trait"></div>
      </div>
      <div className="hidden md:block">
        {applications.length === 0 ? (
          filtersApplied ? (
            <span className="text-gray-1325">
              {t('applications.filter.noResult')}
            </span>
          ) : (
            <NewApplicationCard onClick={openModal} />
          )
        ) : (
          <ApplicationCardList
            applications={applications}
            openModal={openModal}
          />
        )}
      </div>
      <div
        className={`md:hidden block ${applications.length === 0 ? 'ml-[-10px] md:ml-0' : ''}`}
      >
        {applications.length === 0 ? (
          filtersApplied ? (
            t('applications.filter.noResult')
          ) : (
            <span className="text-gray-1325">
              {t('applications.filter.noResult')}
            </span>
          )
        ) : (
          <ApplicationCardListMobile applications={applications} />
        )}
      </div>
    </div>
  )
}
