import React from 'react'
import { IApplication } from 'interfaces/Application'
import Card from 'components/common/Card'
import { useTranslation } from 'react-i18next'

export const ApplicationCardMobile: React.FC<{
  application: IApplication
  handleClick?: () => void
}> = ({ application, handleClick }) => {
  const { t } = useTranslation()
  const nbPromptsActifs = application.prompts.filter(
    (prompt) => prompt.actif
  ).length

  return (
    <Card className="mobile-card" onClick={handleClick}>
      <div className="card-app-content relative">
        <div className="line-clamp-2 font-medium w-[100px] h-[45px] pt-[10px]">
          {application.nomApplication}
        </div>
        <div className="leading-5 absolute bottom-[9px]">
          {t('applications.card.prompt', {
            count: application.prompts.length,
          })}
          <br />
          {t('applications.card.actif', { count: nbPromptsActifs })}
        </div>
      </div>
    </Card>
  )
}
