import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { promptLayout, setPrompts } from 'store/slices/promptLayout'
import Loader from 'components/common/Loader'
import { PromptCardList } from 'components/prompts/PromptCardList'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetAllPromptQuery } from 'api/prompt'

export const PromptList = () => {
  const dispatch = useDispatch()

  const { application } = useSelector(promptLayout)
  const applicationId = application?.id

  const {
    data: prompts,
    isLoading,
    isFetching,
  } = useGetAllPromptQuery(applicationId ? { applicationId } : skipToken)

  useEffect(() => {
    if (prompts) {
      dispatch(setPrompts(prompts))
    }
  }, [application, dispatch])

  if (!application || !prompts || isLoading || isFetching) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return <PromptCardList prompts={prompts} />
}
