import React from 'react'

interface FieldLabelProps {
  label: string
  required?: boolean
  className?: string
}

export const FieldLabel: React.FC<FieldLabelProps> = ({
  label,
  required = false,
  className = '',
}) => (
  <label className={className}>
    {label}
    {required && <span className="text-red-500">&nbsp;*</span>}
  </label>
)
