import React, { FC, useContext } from 'react'
import { Modal as ModalComponent, ModalProps } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { ReactComponent as CloseIcon } from 'assets/images/closeIcon.svg'

const ModalWrapper: FC<React.PropsWithChildren<ModalProps>> = (props) => {
  const { children, classNames: propsClassName } = props
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <ModalComponent
      {...props}
      classNames={{
        modal: `${propsClassName?.modal} ${isDarkTheme ? 'dark' : ''}`,
      }}
      modalId="modal"
      center
    >
      <div
        className="absolute top-[20px] right-[30px] cursor-pointer md:hidden block"
        onClick={props.onClose}
      >
        <CloseIcon />
      </div>
      {children}
    </ModalComponent>
  )
}
export default ModalWrapper
