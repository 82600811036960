import React, { MutableRefObject } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { PromptForm } from 'interfaces/Prompt'
import { FieldLabel } from 'components/common/FieldLabel'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ConsigneIcon } from 'assets/images/consigneIcon.svg'

export const PromptFormFieldsAssisted: React.FC<{
  tacheField: ControllerRenderProps<PromptForm, 'tache'>
  roleField: ControllerRenderProps<PromptForm, 'role'>
  contexteField: ControllerRenderProps<PromptForm, 'contexte'>
  formatField: ControllerRenderProps<PromptForm, 'format'>
  tacheRef: MutableRefObject<HTMLTextAreaElement | null>
  addTextToTextarea: (
    event: React.MouseEvent,
    text: string,
    ref: MutableRefObject<any>,
    fieldName: string
  ) => void
  errors: any
}> = ({
  tacheField,
  roleField,
  contexteField,
  formatField,
  tacheRef,
  addTextToTextarea,
  errors,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <FieldLabel
          className="h-8 md:h-12 md:align-top md:py-3"
          label={t('prompts.form.roleLabel')}
          required
        />
        <div className="h-full flex md:flex-row flex-col w-full">
          <div className="flex flex-col flex-grow">
            <textarea
              placeholder={t('prompts.form.rolePlaceholder')}
              className={`form-textarea h-[150px] w-full md:h-[60px] ${errors.role ? 'error' : ''}`}
              {...roleField}
            />
            {errors.role && (
              <p className="form-error mt-10px">
                {errors.role.type === 'required'
                  ? t('global.form.error.mandatory')
                  : errors.prompt.message}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-[20px]">
        <FieldLabel
          className="h-8 md:h-12 md:align-top md:py-3"
          label={t('prompts.form.tacheLabel')}
          required
        />
        <div className="h-full flex md:flex-row flex-col w-full">
          <div className="flex flex-col flex-grow">
            <textarea
              placeholder={t('prompts.form.tachePlaceholder')}
              className={`form-textarea h-[150px] w-full md:h-[250px] ${errors.tache ? 'error' : ''}`}
              {...tacheField}
              ref={(e) => {
                tacheField.ref(e)
                // eslint-disable-next-line no-param-reassign
                tacheRef.current = e
              }}
            />
            {errors.tache && (
              <p className="form-error mt-10px">
                {errors.tache.type === 'required'
                  ? t('global.form.error.mandatory')
                  : errors.tache.message}
              </p>
            )}
            <div className="flex flex-col md:flex-row md:justify-start my-15px max-h-[45px]">
              <button
                className="button-consigne"
                onClick={(event) =>
                  addTextToTextarea(event, '{consigne}', tacheRef, 'tache')
                }
              >
                <ConsigneIcon />
                <span className="flex-grow text-center">
                  {t('prompts.form.button.consigne')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <FieldLabel
          className="h-8 md:h-12 md:align-top md:py-3"
          label={t('prompts.form.contexteLabel')}
          required
        />
        <div className="h-full flex md:flex-row flex-col w-full">
          <div className="flex flex-col flex-grow">
            <textarea
              placeholder={t('prompts.form.contextePlaceholder')}
              className={`form-textarea h-[150px] w-full md:h-[160px] ${errors.contexte ? 'error' : ''}`}
              {...contexteField}
            />
            {errors.contexte && (
              <p className="form-error mt-10px">
                {errors.contexte.type === 'required'
                  ? t('global.form.error.mandatory')
                  : errors.contexte.message}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col my-[20px]">
        <FieldLabel
          className="h-8 md:h-12 md:align-top md:py-3"
          label={t('prompts.form.formatLabel')}
          required
        />
        <div className="h-full flex md:flex-row flex-col w-full">
          <div className="flex flex-col flex-grow">
            <textarea
              placeholder={t('prompts.form.formatPlaceholder')}
              className={`form-textarea h-[150px] w-full md:h-[100px] ${errors.format ? 'error' : ''}`}
              {...formatField}
            />
            {errors.format && (
              <p className="form-error mt-10px">
                {errors.format.type === 'required'
                  ? t('global.form.error.mandatory')
                  : errors.format.message}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
