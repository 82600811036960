import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import frTranslations from 'i18n/fr/translations.json'

export const resources = {
  fr: { ['translation']: frTranslations },
} as const
i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'fr',
})

export default i18n
