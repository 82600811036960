import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Error404 } from 'components/common/Error404'
import { Error500 } from 'components/common/Error500'

const ErrorHandler: React.FC<{ children: ReactNode }> = ({ children }) => {
  const error = useSelector((state: RootState) => state.error.code)

  if (error === 404) {
    return <Error404 />
  } else if (error === 500) {
    return <Error500 />
  }

  return <>{children}</>
}

export default ErrorHandler
