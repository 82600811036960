const envConstants = {
  dev: {
    API_BASE_URL: 'http://localhost:8087/iamanagementbff/v1',
    KEYCLOAK_URL: 'https://sso.preprod.legal2digital.net',
    KEYCLOAK_REALM: 'qual',
    KEYCLOAK_CLIENT_ID: 'react-lauria',
  },
  qual: {
    API_BASE_URL: 'https://api.qual.legal2digital.net/iamanagementbff/v1',
    KEYCLOAK_URL: 'https://sso.preprod.legal2digital.net',
    KEYCLOAK_REALM: 'qual',
    KEYCLOAK_CLIENT_ID: 'react-lauria',
  },
  preprod: {
    API_BASE_URL: 'todo',
    KEYCLOAK_URL: 'https://sso.preprod.legal2digital.net',
    KEYCLOAK_REALM: 'preprod',
    KEYCLOAK_CLIENT_ID: 'react-lauria',
  },
  prod: {
    API_BASE_URL: 'todo',
    KEYCLOAK_URL: 'https://sso.legal2digital.net',
    KEYCLOAK_REALM: 'prod',
    KEYCLOAK_CLIENT_ID: 'react-lauria',
  },
}

export default envConstants
