import Card from 'components/common/Card'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from 'assets/images/plusIcon.svg'
import { ReactComponent as PromptIconBanner } from 'assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as PromptIconBannerDark } from 'assets/images/prompts/promptIconBannerDark.svg'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const NewPromptCard: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <Card
      onClick={onClick}
      className="w-[260px] h-[155px] group md:mx-0 mx-auto"
    >
      <div className="card-new-prompt">
        <div className="card-prompt-header-wrapper">
          <div className="card-prompt-header new group-hover:dark:bg-gray-1600 group-hover:bg-white">
            {isDarkTheme ? <PromptIconBannerDark /> : <PromptIconBanner />}
          </div>
        </div>
        <div className="new-card-content flex items-center justify-center">
          <div className="flex flex-row items-center">
            <PlusIcon />
            <span className="ml-[20px] font-medium text-xl">
              {t('prompts.newPrompt')}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}
