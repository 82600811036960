import React, { createContext, useState, ReactNode } from 'react'

export type Theme = 'light' | 'dark' | 'system'
interface IDarkThemeContextType {
  theme: Theme
  isDarkTheme: boolean
  setUserTheme: (value: Theme) => void
}

export const DarkThemeContext = createContext<IDarkThemeContextType>({
  theme: 'system',
  isDarkTheme: false,
  setUserTheme: () => {},
})

export const DarkModeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<Theme>(() => {
    if (!('theme' in localStorage)) {
      return 'system'
    }
    if (localStorage.theme === 'dark') {
      return 'dark'
    }

    return 'light'
  })

  const setUserTheme = (value: Theme) => {
    setTheme(value)
    if (value === 'system') {
      localStorage.removeItem('theme')
    } else {
      localStorage.theme = value
    }
  }

  const isDarkTheme =
    theme === 'dark' ||
    (theme === 'system' &&
      window.matchMedia('(prefers-color-scheme: dark)').matches)

  return (
    <DarkThemeContext.Provider
      value={{
        theme,
        isDarkTheme,
        setUserTheme,
      }}
    >
      <div className={`h-full ${isDarkTheme ? 'dark' : ''}`}>{children}</div>
    </DarkThemeContext.Provider>
  )
}
