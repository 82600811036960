import { baseApi } from 'api/base'
import { generatePath } from 'react-router'
import { IModele } from 'interfaces/Modele'

const REFERENTIEL_MODELES_ENDPOINT = '/referentielModeles'
const referentielModelesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllModeles: builder.query<IModele[], void>({
      query: () => generatePath(REFERENTIEL_MODELES_ENDPOINT),
    }),
  }),
})
export const { useGetAllModelesQuery } = referentielModelesApi
export { referentielModelesApi }
