import { useTranslation } from 'react-i18next'
import React from 'react'

export const ActivePromptModalContent: React.FC<{
  handleCancel: () => void
  handleConfirm: () => void
}> = ({ handleConfirm, handleCancel }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col px-[30px]">
      <span>
        {t('prompts.modal.text1')}{' '}
        <span className="my-[5px] text-blue-1350 font-medium uppercase">
          {t('prompts.actif')}
        </span>
      </span>
      <br />
      <span>{t('prompts.modal.text2')}</span>
      <div className="text-right mt-[30px] self-end flex items-center">
        <button className="btn default text-[14px]" onClick={handleCancel}>
          {t('prompts.modal.later')}
        </button>
        <button
          className="btn default w-full md:w-[220px] ml-[20px] text-[14px]"
          onClick={handleConfirm}
        >
          {t('prompts.modal.submit')}
        </button>
      </div>
    </div>
  )
}
