import { PromptSubHeader } from 'components/prompts/PromptSubHeader'
import React from 'react'
import { Outlet } from 'react-router'
import { useSelector } from 'react-redux'
import { promptLayout } from 'store/slices/promptLayout'
import { PromptSubHeaderMobile } from 'components/prompts/PromptSubHeaderMobile'

export const Prompts = () => {
  const { application } = useSelector(promptLayout)
  if (!application) {
    return null
  }
  const promptGlobalStatus =
    application.prompts && application.prompts.some((prompt) => prompt.actif)
      ? 'actif'
      : 'inactif'
  return (
    <div className="prompt-page">
      <PromptSubHeader
        globalStatus={promptGlobalStatus}
        application={application}
      />
      <PromptSubHeaderMobile
        globalStatus={promptGlobalStatus}
        application={application}
      />

      <div className="prompt-content">
        <Outlet />
      </div>
    </div>
  )
}
