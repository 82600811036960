import { useState } from 'react'
import { IDocument } from 'interfaces/Document'

export const usePromptDocumentManagement = () => {
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [newFiles, setNewFiles] = useState<File[]>([])

  return {
    documents,
    setDocuments,
    newFiles,
    setNewFiles,
  }
}
