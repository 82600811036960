import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'
import reportWebVitals from 'reportWebVitals'
import 'i18n'
import { keycloak } from 'keycloak'
import { KeycloakProvider } from 'packages/KeycloakProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <KeycloakProvider
    initOptions={{
      onLoad: 'login-required',
      checkLoginIframe: false,
    }}
    authClient={keycloak}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </KeycloakProvider>
)

reportWebVitals()
