import { IApplication } from 'interfaces/Application'
import React from 'react'
import { ApplicationCard } from 'components/dashboard/ApplicationCard'
import { generatePath, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from 'assets/images/plusIcon.svg'
import { ROUTE_PATHS } from 'constants/routePath'
import { AddButton } from 'components/common/AddButton'

export const ApplicationCardList: React.FC<{
  applications: IApplication[]
  openModal: () => void
}> = ({ applications, openModal }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const redirectApp = (appId: number) => {
    navigate(generatePath(ROUTE_PATHS.PROMPTS, { idApplication: appId }))
  }

  return (
    <div className="card-list hidden md:flex">
      {applications.map((app) => {
        return (
          <div key={app.id} className="flex flex-col">
            <ApplicationCard
              application={app}
              handleClick={() => redirectApp(app.id)}
            />
            <div className="w-[260px] h-auto flex flex-wrap mt-[10px]">
              {app.tags?.map((tag) => {
                return (
                  <div key={tag.id} className="tag-button mr-1 mb-1">
                    <span className="text-app">{tag.nomTag}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
      <button className="button-add-app" onClick={openModal}>
        <PlusIcon />
        <span className="uppercase ml-[15px] font-medium">
          {t('services.newApp')}
        </span>
      </button>
      <div className="add-prompt group" onClick={openModal}>
        <AddButton />
        <span className="ml-[15px] font-medium">{t('services.newApp')}</span>
      </div>
    </div>
  )
}
