import React from 'react'
import { IPromptDetail } from 'interfaces/Prompt'
import { useTranslation } from 'react-i18next'

export const DistributionInput: React.FC<{
  handleDistributionChange: (id: number, value: number) => void
  prompt: IPromptDetail
  isDeleted?: boolean
  error: boolean
}> = ({ handleDistributionChange, prompt, isDeleted, error }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <input
          type="number"
          min={0}
          max={100}
          className={`input-distribution ${isDeleted ? 'deleted' : error ? 'error' : ''}`}
          value={prompt.distribution || 0}
          onChange={(e) => {
            let value = e.target.value
            if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
              handleDistributionChange(
                prompt.id,
                value === '' ? 0 : Number(value)
              )
            }
          }}
          onBlur={(e) => {
            let value = parseFloat(e.target.value)
            if (isNaN(value)) {
              value = 0
            }
            value = Math.max(0, Math.min(100, value))
            handleDistributionChange(prompt.id, value)
          }}
        />
        %
      </div>
      {error && (
        <span className="text-red-1250">
          {t('prompts.diffusion.erreurTotal')}
        </span>
      )}
    </div>
  )
}
