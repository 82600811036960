import React, { Dispatch, SetStateAction } from 'react'
import { ReactComponent as PlusButton } from 'assets/images/plusButtonTemperature.svg'
import { ReactComponent as MinusButton } from 'assets/images/minusButton.svg'
import { ReactComponent as Infobulle } from 'assets/images/infobulle.svg'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'components/common/Tooltip'

export const TemperatureSlider: React.FC<{
  value: number
  setValue?: Dispatch<SetStateAction<number>>
  edition?: boolean
}> = ({ value, setValue = () => {}, edition = false }) => {
  const { t } = useTranslation()
  const decrement = () => {
    if (value > 0 && edition) {
      setValue((prevValue) => {
        if (prevValue === 0.1) {
          return 0
        } else {
          return Math.max(0, Math.round((prevValue - 0.1) * 10) / 10)
        }
      })
    }
  }
  const increment = () => {
    if (value < 1 && edition) {
      setValue((prevValue) => {
        if (prevValue === 0.9) {
          return 1
        } else {
          return Math.max(0, Math.round((prevValue + 0.1) * 10) / 10)
        }
      })
    }
  }
  const text = t(`temperatureDescriptions.${value.toFixed(1)}.text`)
  const infobulle = t(`temperatureDescriptions.${value.toFixed(1)}.infobulle`)
  const getBgColor = (value: number) => {
    if (value === 0) return 'bg-gray-1375'
    if (value <= 0.3) return 'bg-blue-1350'
    if (value <= 0.6) return 'bg-yellow-1500'
    if (value < 1) return 'bg-red-1250'
    return 'bg-purple-1250'
  }
  const getTextColor = (value: number) => {
    if (value === 0) return 'text-gray-1375'
    if (value <= 0.3) return 'text-blue-1350'
    if (value <= 0.6) return 'text-yellow-1500'
    if (value < 1) return 'text-red-1250'
    return 'text-purple-1250'
  }

  return (
    <div className="flex items-center">
      <MinusButton
        onClick={decrement}
        className={`temp-btn ${edition ? 'block' : 'hidden'}`}
      />

      <div className="slider">
        <div
          className={`h-full ${getBgColor(value)} rounded-full`}
          style={{
            width: `${15 + 85 * value}%`,
          }}
        ></div>
        <span className="absolute left-[11px] text-white">
          {value !== 0 && value !== 1
            ? value.toFixed(1).replace('.', ',')
            : value}
        </span>
      </div>

      <PlusButton
        onClick={increment}
        className={`temp-btn ${edition ? 'block' : 'hidden'}`}
      />

      <div className="md:flex hidden items-center space-x-1">
        <span className={`${getTextColor(value)}`}>{text}</span>
        <div className={`${edition ? 'block' : 'hidden'}`}>
          <Tooltip text={infobulle}>
            <span className="text-blue-500 cursor-pointer ml-10px">
              <Infobulle />
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
