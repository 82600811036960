import React, { FC, ReactNode } from 'react'

export const ModalBody: FC<
  React.PropsWithChildren<{
    className?: string
    children?: ReactNode
  }>
> = ({ className = 'modal-body', children }) => (
  <div className={className} id="modalBody">
    {children}
  </div>
)
