import { IPromptDetail } from 'interfaces/Prompt'
import Card from 'components/common/Card'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { ReactComponent as PromptIconBanner } from 'assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as PromptIconBannerDark } from 'assets/images/prompts/promptIconBannerDark.svg'
import { ReactComponent as PromptIconBannerDeletedDark } from 'assets/images/prompts/promptIconBannerDeletedDark.svg'
import { ReactComponent as PromptIconBannerDeleted } from 'assets/images/prompts/promptIconBannerDeleted.svg'

export const PromptDiffusionCard: React.FC<{
  prompt: IPromptDetail
  isDeleted?: boolean
}> = ({ prompt, isDeleted }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <Card
      className={`card-prompt-distribution ${isDeleted ? 'deleted' : ''}`}
      withHoverStyle={false}
    >
      <div className="card-prompt-header-distribution">
        {isDarkTheme ? (
          isDeleted ? (
            <PromptIconBannerDeletedDark />
          ) : (
            <PromptIconBannerDark />
          )
        ) : isDeleted ? (
          <PromptIconBannerDeleted />
        ) : (
          <PromptIconBanner />
        )}
        <span className="ml-4">{prompt.nomPrompt}</span>
      </div>
      <div className="md:line-clamp-3 line-clamp-2 leading-tight text-left px-30px">
        {prompt.prompt}
      </div>
    </Card>
  )
}
