import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Layout } from 'pages/Layout'
import 'assets/styles/style.css'
import { Provider } from 'react-redux'
import store, { persistor } from 'store'
import { UseKeycloakService } from 'packages/KeycloakProvider'
import Loader from 'components/common/Loader'
import { Error404 } from 'components/common/Error404'
import ErrorHandler from 'components/common/ErrorHandler'
import { Error500 } from 'components/common/Error500'
import { ROUTE_PATHS } from 'constants/routePath'
import { Dashboard } from 'pages/Dashboard'
import { PromptList } from 'pages/prompts/PromptList'
import { PromptDistribution } from 'pages/prompts/PromptDistribution'
import { Prompt } from 'pages/prompts/Prompt'
import { PromptEdition } from 'pages/prompts/PromptEdition'
import { LayoutApplication } from 'pages/applications/LayoutApplication'
import { Tags } from 'pages/Tags'
import { Accueil } from 'pages/Accueil'
import { Conversation } from 'pages/Conversation'
import { LayoutAccueil } from 'pages/LayoutAccueil'
import { PersistGate } from 'redux-persist/integration/react'
import { Prompts } from 'pages/prompts/Prompts'

function App() {
  const kc = UseKeycloakService()
  const isLoading = !kc.isInitialized()
  const {
    ACCUEIL,
    CONVERSATION,
    DISTRIBUTION,
    PROMPTS,
    PROMPT,
    PROMPT_CREATE,
    PROMPT_EDIT,
    ERROR,
    TAG_ADMIN,
  } = ROUTE_PATHS
  if (isLoading) {
    return (
      <Loader center className="h-screen flex items-center justify-center" />
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorHandler>
          <BrowserRouter>
            <Routes>
              <Route path="" element={<Layout />}>
                <Route path="/" element={<Dashboard />} />
              </Route>
              <Route path="" element={<LayoutAccueil />}>
                <Route path={ACCUEIL} element={<Accueil />} />
              </Route>
              <Route path="" element={<LayoutAccueil withHeader />}>
                <Route path={CONVERSATION} element={<Conversation />} />
              </Route>
              <Route path="" element={<Layout />}>
                <Route path={TAG_ADMIN} element={<Tags />} />
              </Route>
              <Route path="" element={<LayoutApplication />}>
                <Route path={PROMPTS} element={<Prompts />}>
                  <Route index element={<PromptList />} />
                  <Route path={DISTRIBUTION} element={<PromptDistribution />} />
                </Route>
                <Route path={PROMPT} element={<Prompt />} />
                <Route path={PROMPT_EDIT} element={<PromptEdition />} />
                <Route path={PROMPT_CREATE} element={<PromptEdition />} />
              </Route>
              <Route path="" element={<Layout itemsCenter />}>
                <Route path={ERROR} element={<Error500 />} />
                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ErrorHandler>
      </PersistGate>
    </Provider>
  )
}

export default App
