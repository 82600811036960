import { useDispatch } from 'react-redux'
import { usePostFeedbackMutation } from 'api/feedback'
import { IFeedbackBody } from 'interfaces/Feedback'
import { ROUTE_PATHS } from 'constants/routePath'
import { useNavigate } from 'react-router'

export const useHandleRateMessage = (
  application: string,
  messageId: number
) => {
  const [postFeedback, { isLoading }] = usePostFeedbackMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleRateMessage = (rating: number) => {
    if (messageId === 0) return
    const feedback: IFeedbackBody = {
      application,
      message_id: messageId,
      note: rating,
    }
    postFeedback(feedback)
      .unwrap()
      .then(() => {
        dispatch({
          type: 'conversation/addFeedback',
          payload: {
            application,
            feedback: rating,
            id: messageId,
          },
        })
      })
      .catch((error) => {
        console.error(error)
        navigate(ROUTE_PATHS.ERROR)
      })
  }
  return { handleRateMessage, isLoading }
}
