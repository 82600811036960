import { ReactComponent as Avatar } from 'assets/images/avatar.svg'
import { ReactComponent as AvatarDark } from 'assets/images/avatarDark.svg'
import { FC, useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const UserCard: FC<{ username: string }> = ({ username }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="card-user relative">
      <div>{isDarkTheme ? <AvatarDark /> : <Avatar />}</div>
      <div className="username-wrapper">
        <div className="username">{username}</div>
        <div className="trait"></div>
      </div>
    </div>
  )
}
