export const usageParams = [
  {
    logo: 'aideasLogo.svg',
    logoDark: 'aideasLogoDark.svg',
    usageKey: 'AIdeas',
    application: 'AIDEAS',
  },
  {
    logo: 'promptAssistantLogo.svg',
    logoDark: 'promptAssistantLogoDark.svg',
    usageKey: 'promptAssistant',
    application: 'PROMPTASSISTANT',
  },
  {
    logo: 'promptPlusLogo.svg',
    logoDark: 'promptPlusLogoDark.svg',
    usageKey: 'promptPlus',
    application: 'PROMPTPLUS',
  },
]
