import Card from 'components/common/Card'
import { IPrompt } from 'interfaces/Prompt'
import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { ReactComponent as PromptIconBanner } from 'assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as PromptIconBannerDark } from 'assets/images/prompts/promptIconBannerDark.svg'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

export const PromptCard: React.FC<{
  prompt: IPrompt
  handleClick?: () => void
}> = ({ prompt, handleClick }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <Card className="card-prompt group" onClick={handleClick}>
      <div className="card-prompt-header-wrapper">
        <div className="card-prompt-header general pl-[20px] group-hover:dark:bg-gray-1600 group-hover:bg-white">
          {isDarkTheme ? <PromptIconBannerDark /> : <PromptIconBanner />}
          <div className="flex flex-col ml-[20px] prompt-statut ">
            <span>{prompt.nomPrompt}</span>
            <div className={`${prompt.actif ? 'actif' : 'inactif'}`}>
              {prompt.actif ? t('prompts.actif') : t('prompts.inactif')}
            </div>
          </div>
        </div>
      </div>
      <div className="line-clamp-2 leading-tight mt-[10px] text-left px-[10px]">
        {prompt.prompt}
      </div>
    </Card>
  )
}
