import Card from 'components/common/Card'
import { useTranslation } from 'react-i18next'
import { IApplication } from 'interfaces/Application'
import { useContext } from 'react'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { getIconeInfo } from 'utils/service'

export const ApplicationCard: React.FC<{
  application: IApplication
  handleClick?: () => void
}> = ({ application, handleClick }) => {
  const { t } = useTranslation()
  const nbPromptsActifs = application.prompts.filter(
    (prompt) => prompt.actif
  ).length
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { color, colorDark, svgPathDark, svgPath } = getIconeInfo(
    application.service
  )
  return (
    <Card className="application-card mx-auto md:mx-0" onClick={handleClick}>
      <div className="card-app-header">
        <img
          className="h-[30px] mx-15px"
          src={isDarkTheme ? svgPathDark : svgPath}
          alt="Banner Icon"
        />
        <div className="flex flex-col">
          <span
            className="service-name"
            style={{ color: isDarkTheme ? colorDark : color }}
          >
            {application.nomApplication}
          </span>
        </div>
      </div>
      <div className="flex-grow">
        <div className="card-app-content flex-col mt-10px">
          <div className="app-name">{application.service.nomService}</div>
          <div className="leading-5">
            {t('applications.card.prompt', {
              count: application.prompts.length,
            })}
            <br />
            {t('applications.card.actif', { count: nbPromptsActifs })}
          </div>
        </div>
      </div>
    </Card>
  )
}
