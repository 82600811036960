import { FieldLabel } from 'components/common/FieldLabel'
import { FormSelect } from 'components/common/FormSelect'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAllTagsQuery } from 'api/tag'
import { TAG_VISIBLE_STATUT } from 'constants/general'
import { ITag } from 'interfaces/Tag'
import { ReactComponent as TagIcone } from 'assets/images/tagIcone.svg'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'

export const TagSelect: React.FC<{
  selectedTags: { id: number; label: string }[]
  setSelectedTags: any
  boldLabel?: boolean
  displayDefaultOption?: boolean
  optionLink?: boolean
}> = ({
  selectedTags,
  setSelectedTags,
  boldLabel = false,
  displayDefaultOption = true,
  optionLink = false,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const [tagOptions, setTagOptions] = useState<
    { value: string; label: string }[]
  >([])
  const { data: tags, isLoading } = useGetAllTagsQuery()
  const getOptionFromTags = (tags: ITag[]) => {
    return tags
      .filter((tag) => tag.statut === TAG_VISIBLE_STATUT)
      .map((tag) => {
        return { value: tag.id.toString(), label: tag.nomTag }
      })
  }
  useEffect(() => {
    const options = tags ? getOptionFromTags(tags) : []
    setTagOptions(options)
  }, [tags])
  useEffect(() => {
    if (tags) {
      setTagOptions(
        getOptionFromTags(tags).filter(
          (tag) =>
            !selectedTags.some(
              (selectedTag) => selectedTag.id.toString() === tag.value
            )
        )
      )
    }
  }, [selectedTags])
  const handleTagSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    if (
      !selectedTags.find(
        (tag: { id: number; label: string }) =>
          tag.id === parseInt(event.target.value)
      )
    ) {
      setSelectedTags([
        ...selectedTags,
        {
          id: parseInt(event.target.value),
          label:
            tagOptions?.find((tag) => tag.value === event.target.value)
              ?.label ?? '',
        },
      ])
      setTagOptions(tagOptions.filter((tag) => tag.value != event.target.value))
      setValue('')
    }
  }
  const goToTagAdmin = () => {
    navigate(ROUTE_PATHS.TAG_ADMIN)
  }

  return (
    <div className="md:flex md:flex-col">
      <div className="md:flex md:flex-row w-[300px] h-[45px] items-center">
        <FieldLabel
          className={`flex items-center h-full ${boldLabel ? 'font-semibold' : ''}`}
          label={
            optionLink
              ? t('applications.filter.tagLabel')
              : t('applications.form.tagLabel')
          }
        />
        {optionLink && (
          <div className="option-link" onClick={goToTagAdmin}>
            {t('tags.optionLink')}{' '}
            <TagIcone className="ml-2 h-[20px] w-[22px]" />
          </div>
        )}
      </div>
      <FormSelect
        placeholder={t('applications.form.tagPlaceholder')}
        options={tagOptions ?? []}
        fieldName="tag"
        wrapperClassName="md:w-[300px]"
        wrapperErrorClassName="h-[0px]"
        onChange={(event) => handleTagSelect(event)}
        isLoading={isLoading}
        value={value}
        displayDefaultOption={displayDefaultOption}
      />
    </div>
  )
}
