import React from 'react'
import { DarkModeProvider } from 'contexts/DarkThemeContext'
import { ReactComponent as LogoLauria } from 'assets/images/logoLauria.svg'
import { AccueilHeader } from 'components/accueil/AccueilHeader'
import { DarkThemeToggle } from 'components/layout/DarkThemeToggle'
import { generatePath, Outlet, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'

export const LayoutAccueil: React.FC<{ withHeader?: boolean }> = ({
  withHeader = false,
}) => {
  const navigate = useNavigate()
  const goToAccueil = () => {
    navigate(generatePath(ROUTE_PATHS.ACCUEIL))
  }

  return (
    <DarkModeProvider>
      <div className="layout-container">
        <div className="layout-header md:min-h-[100px]">
          <div className="flex-shrink-0">
            <LogoLauria className="logo-lauria" onClick={goToAccueil} />
          </div>
          {withHeader && (
            <div className="hidden md:flex items-center">
              <AccueilHeader />
            </div>
          )}
          <div className="flex-shrink-0">
            <DarkThemeToggle />
          </div>
        </div>
        <Outlet />
      </div>
    </DarkModeProvider>
  )
}
