import { IDocument } from 'interfaces/Document'
import Card from 'components/common/Card'
import { useTranslation } from 'react-i18next'
import React, { SetStateAction } from 'react'
import { ReactComponent as DeleteIcon } from 'assets/images/closeTagButton.svg'
import { ReactComponent as OkIcone } from 'assets/images/okIcone.svg'

export const PromptDocumentList: React.FC<{
  documents: IDocument[]
  newFiles?: File[]
  withDelete?: boolean
  setDocuments?: (value: SetStateAction<IDocument[]>) => void
  setNewFiles?: (value: SetStateAction<File[]>) => void
}> = ({
  documents,
  newFiles,
  withDelete,
  setDocuments = () => {},
  setNewFiles = () => {},
}) => {
  const { t } = useTranslation()
  const deleteDoc = (indexToDelete: number) => {
    if (withDelete) {
      const updatedDocuments = documents.filter(
        (doc, index) => index !== indexToDelete
      )
      setDocuments(updatedDocuments)
      if (newFiles) {
        const updatedNewFiles = newFiles?.filter(
          (doc, index) => index !== indexToDelete
        )
        setNewFiles(updatedNewFiles)
      }
    }
  }

  return (
    <Card
      className={`w-full pb-[20px] md:block ${withDelete ? (documents.length > 0 ? 'block' : 'hidden') : ''}`}
      withHoverStyle={false}
    >
      <div className="prompt-document-list-content">
        <div className="prompt-count md:flex hidden">
          {t('prompts.documents.charged', { count: documents.length })}
        </div>
        {documents.length > 0 && (
          <>
            <div className="trait md:block hidden"></div>
            <div className="mt-[10px]">
              {documents.map((doc, index) => (
                <div
                  key={index}
                  className="flex flex-row w-full justify-between items-center mb-10px"
                >
                  <div className="w-1/6 text-left">
                    <OkIcone className="text-green-1200" />
                  </div>
                  <div className="flex-grow w-9/12 break-words whitespace-normal">
                    {doc.nomDocument}
                  </div>
                  {withDelete && (
                    <div
                      className="delete-doc w-1/12"
                      onClick={() => deleteDoc(index)}
                    >
                      <DeleteIcon />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Card>
  )
}
