import React, { useState } from 'react'
import { ReactComponent as FillStar } from 'assets/images/fillStar.svg'
import { ReactComponent as OutlineStar } from 'assets/images/outlineStar.svg'

const StarRating: React.FC<{
  initialRate?: number
  onClick?: (rating: number) => void
}> = ({ initialRate, onClick = () => {} }) => {
  const defaultRate = initialRate ?? 0
  const [hoveredStar, setHoveredStar] = useState(0)
  const [selectedRating, setSelectedRating] = useState(defaultRate)

  const handleMouseEnter = (rating: number) => {
    setHoveredStar(rating)
    setSelectedRating(0)
  }

  const handleMouseLeave = () => {
    setHoveredStar(0)
    setSelectedRating(defaultRate)
  }

  const handleClick = (rating: number) => {
    setSelectedRating(rating)
    onClick(rating)
  }

  return (
    <div className="flex space-x-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <div
          key={star}
          onMouseEnter={() => handleMouseEnter(star)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(star)}
          className="cursor-pointer"
        >
          {selectedRating >= star ? (
            <FillStar className="w-[30px] h-[30px] text-blue-1400" />
          ) : hoveredStar >= star ? (
            <FillStar className="w-[30px] h-[30px] text-blue-1225" />
          ) : (
            <OutlineStar className="w-[30px] h-[30px] text-blue-1400" />
          )}
        </div>
      ))}
    </div>
  )
}

export default StarRating
