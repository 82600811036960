import React, { FC } from 'react'

export const ModalHeader: FC<
  React.PropsWithChildren<{
    headerClassName?: string
    titleClassName?: string
  }>
> = ({
  headerClassName = 'modal-header',
  titleClassName = 'modal-title',
  children,
}) => (
  <div className={headerClassName} id="modalHeader">
    <h3 className={titleClassName}>{children}</h3>
  </div>
)
