import React, { useState } from 'react'
import { ReactComponent as PromptIcon } from 'assets/images/prompts/promptIcon.svg'
import { useTranslation } from 'react-i18next'
import { IApplication } from 'interfaces/Application'
import { FormApplicationModal } from 'components/applications/FormApplicationModal'
import { ReactComponent as AppOptionIcon } from 'assets/images/appOption.svg'
import { ReactComponent as DiffusionIcon } from 'assets/images/prompts/diffusionIcon.svg'
import { NavLink } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/routePath'
import { generatePath } from 'react-router'

export interface PromptSubHeaderProps {
  globalStatus: string
  application: IApplication
}

export const PromptSubHeader: React.FC<PromptSubHeaderProps> = ({
  globalStatus,
  application,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const openModal = () => {
    setOpen(true)
  }

  return (
    <div className="prompt-sub-header">
      <div className="tabs-prompt">
        <NavLink
          to={generatePath(ROUTE_PATHS.PROMPTS, {
            idApplication: application.id,
          })}
          end
          className={({ isActive }) =>
            `pl-[30px] tab-link-prompt ${isActive ? 'tab-prompt-active' : ''}`
          }
        >
          <div className="tab pl-[25px]">
            <PromptIcon />
            <div className="flex flex-col ml-8">
              <h3>{t('prompts.title')}</h3>
              <span className={`prompt-status ` + globalStatus}>
                {t(`prompts.${globalStatus}`)}
              </span>
            </div>
          </div>
          <div className="trait"></div>
        </NavLink>
        <NavLink
          to={generatePath(ROUTE_PATHS.DISTRIBUTION, {
            idApplication: application.id,
          })}
          className={({ isActive }) =>
            `tab-link-prompt ${isActive ? 'tab-prompt-active' : ''}`
          }
        >
          <div className="tab border-l dark:border-gray-1350 border-gray-1125 pl-[20px]">
            <DiffusionIcon />
            <div className="flex flex-col ml-15px">
              <h3>{t('prompts.distrib.title')}</h3>
              <span>{t('prompts.distrib.subTitle')}</span>
            </div>
          </div>
          <div className="trait"></div>
        </NavLink>
      </div>
      <div className="ml-auto flex items-center mr-60px">
        <div className="btn-option" onClick={openModal}>
          <AppOptionIcon />
          <div className="flex flex-col ml-15px">
            <h3>{t('prompts.options')}</h3>
            <span>{t('prompts.optionsText')}</span>
          </div>
        </div>
      </div>
      <FormApplicationModal
        open={open}
        setOpen={setOpen}
        type="edit"
        application={application}
      />
    </div>
  )
}
