import React, { SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { ReactComponent as DocumentIcon } from 'assets/images/prompts/uploadDocument.svg'
import { ReactComponent as DocumentIconDark } from 'assets/images/prompts/uploadDocumentDark.svg'
import { IDocument } from 'interfaces/Document'
import { PromptDocumentList } from 'components/prompts/PromptDocumentList'
import { ReactComponent as ChevronDown } from 'assets/images/chevronDown.svg'
import { ReactComponent as ChevronUp } from 'assets/images/chevronUp.svg'

export const PromptDocuments: React.FC<{
  documents: IDocument[]
  setDocuments?: (value: SetStateAction<IDocument[]>) => void
  newFiles: File[]
  setNewFiles: (value: SetStateAction<File[]>) => void
  withDelete?: boolean
}> = ({
  documents,
  setDocuments = () => {},
  newFiles,
  setNewFiles,
  withDelete = false,
}) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [displayDocs, setDisplayDocs] = useState<boolean>(true)
  const handleFileChange = (event: any) => {
    const filesName = newFiles.map((file) => file.name)
    if (
      event.target.files[0] &&
      !filesName.includes(event.target.files[0].name)
    ) {
      setDocuments([
        ...documents,
        {
          nomDocument: event.target.files[0].name,
          mimeType: event.target.files[0].type,
        },
      ])
      setNewFiles([...newFiles, event.target.files[0]])
    }
  }

  return (
    <div className="card-doc">
      <div className="card-document-content">
        <div className="upload-doc-card">
          <div>
            <label htmlFor="file-upload">
              <div className="upload-doc">
                {isDarkTheme ? (
                  <DocumentIconDark className="hover:cursor-pointer" />
                ) : (
                  <DocumentIcon className="hover:cursor-pointer" />
                )}
              </div>
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>
          <div className="uppercase font-medium pl-[10px]">
            {t('prompts.documents.new')}
          </div>
        </div>
        <div className="md:hidden prompt-count flex mb-10px flex-row items-center">
          <span>
            {t('prompts.documents.charged', { count: documents.length })}
          </span>
          <div
            onClick={() => setDisplayDocs(!displayDocs)}
            className={`ml-auto mr-[10px] ${documents.length > 0 ? 'block' : 'hidden'}`}
          >
            {displayDocs ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
        <div className={`md:block ${displayDocs ? 'block' : 'hidden'}`}>
          <PromptDocumentList
            documents={documents}
            withDelete={withDelete}
            setDocuments={setDocuments}
            newFiles={newFiles}
            setNewFiles={setNewFiles}
          />
        </div>
      </div>
    </div>
  )
}
